// import logo from './logo.svg';
// import './App.css';
// import { 
//   BrowserRouter as Router,
//   Route,
//   Link
// } from 'react-router-dom';
import {
  Container,
  Typography,
  Divider,
  Link
} from '@material-ui/core';

function App() {
  return (
    <div className="App">
      <Container style={{marginTop: 20, fontFamily: "Helvetica"}}>
        <Typography variant="h4">Dec 2021/Jan 2022 Lucid Dreaming Class by thispartofus (Chris)</Typography>
        <Divider style={{marginTop: 10}}/>
        <div style={{margin: 10}}>
          <Typography variant="h6" style={{ marginBottom: 10}}>Jan 8st, 2022</Typography>
          <Typography variant="b6">Class 2 Link:</Typography>
          <br />
          <br />
          <Typography variant="b6">We will be going live shortely at 1pm PST, hope to see you there!</Typography>
          <br />
          <br />          
          <Link href="https://www.youtube.com/watch?v=x1ty8aEtk-Y">https://www.youtube.com/watch?v=x1ty8aEtk-Y</Link>

        </div>        
        <Divider style={{marginTop: 10}}/>
        <div style={{margin: 10}}>
          <Typography variant="h6" style={{ marginBottom: 10}}>Jan 1st, 2022</Typography>
          <Typography variant="b6">Class 1 Link:</Typography>
          <br />
          <br />
          <Typography variant="b6">We will be going live shortely at 1pm PST, hope to see you there!</Typography>
          <br />
          <br />          
          <Link href="https://www.youtube.com/channel/UCGrJ7c67yrnJSBdBNCoXILw">https://www.youtube.com/channel/UCGrJ7c67yrnJSBdBNCoXILw</Link>

        </div>
        <Divider style={{marginTop: 10}}/>
        <div style={{margin: 10}}>
          <Typography variant="h6" style={{ marginBottom: 10}}>Dec 28th, 2022</Typography>
          <Typography variant="b6">Class 1 -- Attaining Lucidy</Typography>
          <br />
          <br />
          <Typography variant="b6">The first class will be on (convert to your local timezone):</Typography>
          <br />
          <Typography variant="b6">Sat Jan 1st 1pm PST</Typography>
          <br />
          <Typography variant="b6">Sat Jan 1st 9pm GMT</Typography>
          <br />
          <Typography variant="b6">Sun Jan 2nd 8AM AEDT</Typography>
          <br />
          <br />
          <Typography variant="b6">This first class will primarily be focused on ATTAINING LUCIDITY. We will go over background information about sleep/dreams, daytime practices/techniques, nighttime practices/techniques, motivation, and how to put all these ideas together to form your own personal practice routine. The day of the class I will post notes for the first class which will go into the content in much more detail, and a link to the livestream.</Typography>
          <br />
          <br />  
          <Typography variant="b6">Based on the survey results the livestream w/ live chat format seemed to be the most popular, with the zoom conference style coming in second place. So I think for the first class at least we will try the following:</Typography>
          <br />
          <br /> 
          <Typography variant="b6">The class itself will be a livestream (on Youtube probably), and will be about an hour. I'll be answering questions from the chat that are relevant to the topic at hand periodically throughout the class. After the class I'll be hopping in a discord voice server for an informal discussion session where people can join and ask questions about whatever they like (but questions about the topics covered in that first class will probably get priority.)</Typography>
          <br />
          <br />  
          <Typography variant="b6">Lastly, I'd like to mention that this format may change for the second class based on feedback from the first class. I've never done this before so I'm definitely not sure that this is the perfect way to do it, so thank you for being patient if things don't go smoothly. All constructive feedback will help shape this into a better experience for everyone in the future.</Typography>
          <br />
          <br /> 
          <Typography variant="b6">I'm excited for Saturday! I hope you all are too.</Typography>
          <br />
          <br />  
          <Typography variant="b6">Much love and happy dreaming</Typography>
          <br />
          <Typography variant="b6">Chris</Typography>


        </div>
        <Divider style={{marginTop: 10}}/>
        <div style={{margin: 10}}>
          <Typography variant="h6" style={{ marginBottom: 10}}>December 24th, 2021</Typography>
          <Typography variant="b6">Hello and welcome! I'm glad you all are here.</Typography>
          <br />
          <br />
          <Typography variant="b6">This will be where all major announcements about the class will be posted. The first order or business is nailing down a schedule and rough syllabus. Since this class is for YOU, I’d love to learn more about your background and what you’d like to get out of this class. Linked below is a Discord server where I have a very short survey and a few channels for collecting input about the class content and format. It is also a place for community discussion for the duration of the class, and hope to hear from many of you there.</Typography>
          <br />
          <br />          
          <Link href="https://discord.gg/kpRGsyyUCC">https://discord.gg/kpRGsyyUCC</Link>
          <br />
          <br />
          <Typography variant="b6">I hope to have the schedule set within a week, so please check back then if you are not in the Discord. I can't wait to get started!</Typography>

        </div>
      </Container>
    </div>
  );
}

export default App;
